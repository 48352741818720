.question-editor-card {
    padding: 25px;
}

.question-editor-card > div > div {
    margin-bottom: 20px;
}

.mdx-editor-content {
    background-color: rgb(0, 0, 0, 0.06);
    min-height: 80px;
    overflow: scroll; 
}

.mdx-editor-hint {
    background-color: rgb(0, 0, 0, 0.06);
    min-height: 80px;
    overflow: scroll; 
}

div[data-radix-popper-content-wrapper] {
    z-index: 2000 !important;
}