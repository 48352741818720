

.app-header {
    flex-basis: 40px;

    min-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: calc(10px + 2vmin);
  }
  
  .app-logo {
    height: 30px;
    pointer-events: none;
  }

