/* .MuiAccordionSummary-root {
  min-height: 15px;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 15px;
} */


/* .stripe-pane-accordion-summary {
  min-height: "0px!important";
}

.css-d5sqwh-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 64px;
} */

.MuiAccordionSummary-root {
  min-height: 32px!important;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 32px!important;
  
}

/* .MuiAccordion-root {
  min-height: 0px!important;
  height: 15px!important;
} */

/* .Mui-expanded {
  min-height: 20px!important;
  
} */