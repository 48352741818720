html {
  margin: 0;
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.00em;
}

/* Prevent iOS 'bounce' */
html, body {
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
}


#root {
  display: flex;
  min-height: 100dvh;
  background-color: #505050;
  justify-content: center;
  align-items: start;
  padding: 0;
}

