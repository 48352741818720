:root {
  --mcButtonPurple: #7304a8;
}

.multichoice-button {
  background-color: var(--mcButtonPurple);
  filter: drop-shadow(0px 0px 2px rgba(50,50,50,0.3));
  border: solid 1px #dddddd !important;
}

.multichoice-button.selected, .multichoice-button.selected:hover  {
/** Created with https://www.css-gradient.com*/
background: #A088AB;
background: radial-gradient(at right top, #A088AB, #7405A8);
}

.multichoice-button:not(.selected), .multichoice-button:not(.selected):hover {
  background-color: var(--mcButtonPurple);
}
