.game-main {
  flex-grow: 1;
  overflow: auto;
  font-family: 'Quicksand, sans-serif';
  font-weight: 500;
  display: flex;
  flex-direction: column;
  padding: 18px;
  color: white;
}


/* Style for the scrollbar track */
.game-main::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  background-color: transparent;
  /* Set the background color to transparent */
}

/* Style for the scrollbar handle */
.game-main::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.25);
  /* Color of the scrollbar handle */
  border-radius: 6px;
  /* Rounded corners of the scrollbar handle */
}

/* Style for the scrollbar handle on hover */
.game-main::-webkit-scrollbar-thumb:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.progress-bar {
  height: 20px;
  min-height: 20px;
  flex-grow: 1;
}
