

.canned-responses {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.canned-response {
    width: 100%;
    padding-right: 75px;
}

.circle-icon-correct {
    color: green;
}
