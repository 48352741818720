.bs-main {
    display: flex;
    flex-direction: column;
    max-width: 100%;

    border-left: 1px solid #b2b2b2;
    border-bottom: 1px solid #b2b2b2;
    border-right: 1px solid #b2b2b2;
    border-radius: 5px;
}

.childrenContainer {
    padding: 1em;
}

.bs-header {
    display: flex;
    flex-direction: row;
    width: 100% !important;
}

.bs-headerBorderBefore {
    border-top: 1px solid #b2b2b2;
    width: 1em;
    border-top-left-radius: 5px;
}

.bs-headerTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 0.25em;
    width: fit-content;
    height: 2em;
    margin: -1em 0.5em 0em 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bs-headerBorderAfter {
    border-top: 1px solid #b2b2b2;
    width: 1em;
    flex-grow: 2;
    border-top-right-radius: 5px;
}

